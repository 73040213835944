import axios from "axios";
import { getSessionToken } from "@shopify/app-bridge/utilities";

import createApp from "@shopify/app-bridge";

export const axiosInterceptor = (shop, host) => {
    const app = createApp({
        apiKey: process.env.GATSBY_SHOPIFY_APP_API_KEY,
        forceRedirect: true,
        host: host
        });
    
    const axiosWithToken = axios.create();
    axiosWithToken.interceptors.request.use(function (config) {
    
        return getSessionToken(app)
            
        .then((token) => {
        // Append your request headers with an authenticated token    
        config.headers["Authorization"] = `Bearer ${token}`;
            
        return config;
        
    }).catch(function(e) {
        console.error("e.....",e); 
    });
    });
 return axiosWithToken;
}